import React from "react"

// ---------------------------------------------------------

import RenderBlocks from "@lib/renderBlocks"
import Layout from "../../layout"
import RecommendedReading from "@components/recommended-reading"

// ---------------------------------------------------------

const FlexiblePage = ({
  data,
  recommended,
  children,
  preview,
  pageTitle,
  featuredImage,
  excerpt
}) => {
  const meta = {
    "og:title": pageTitle ? pageTitle : data?.title,
    title: pageTitle ? pageTitle : data?.title,
    "og:image": featuredImage?.url || data?.featuredImage?.url,
    description: excerpt ? excerpt : data?.excerpt,
    "og:description": excerpt ? excerpt : data?.excerpt,
    robots: data?.isPrivate ? "noindex" : "index"
  }

  // ---------------------------------------------------------
  return (
    <Layout
      preview={preview}
      customMeta={meta}
      requestDemo={{ label: data?.demoLabel, url: data?.demoUrl }}
      logoOnly={data?.hideNavItems == "Hide all" || false}
      hideCtas={data?.hideNavItems == "Hide CTAs" || false}
    >
      {data?.modulesCollection && RenderBlocks(data.modulesCollection.items)}
      {children}
      <RecommendedReading recommended={recommended} />
    </Layout>
  )
}

export default FlexiblePage
